import ContactPopup from "components/Contact/ContactPopup";
import GoogleTagManager from "components/GoogleTagmanager";
import { CategoryProvider } from "contexts/CategoryContext"
import { PopupProvider } from "contexts/PopupContext";
import Blog from "pages/Blog/blog";
import SingleBlog from "pages/Blog/SingleBlog";
import Home from "pages/Home/Home";
import MobileHome from "pages/Home/MobileHome";
import MobileServices from "pages/Services/MobileServices";
import Services from "pages/Services/Services";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const App = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(
    window.matchMedia("(min-width: 1024px)").matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1024px)");

    const handleMediaChange = (event: MediaQueryListEvent) =>
      setIsLargeScreen(event.matches);

    mediaQuery.addEventListener("change", handleMediaChange);

    return () => mediaQuery.removeEventListener("change", handleMediaChange);
  }, []);

  return (

      <div id="appCont" className="">
        <GoogleTagManager />

        <PopupProvider>
          <CategoryProvider>
            <ContactPopup />
            <BrowserRouter>
              <Routes>
                <Route
                  path="/"
                  element={isLargeScreen ? <Home /> : <MobileHome />}
                />
                <Route
                  path="/services"
                  element={isLargeScreen ? <Services /> : <MobileServices />}
                />
                <Route
                  path="/services/faq"
                  element={isLargeScreen ? <Services /> : <MobileServices />}
                />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/:title/:id" element={<SingleBlog />} />
                <Route
                  path="*"
                  element={isLargeScreen ? <Home /> : <MobileHome />}
                />
              </Routes>
            </BrowserRouter>
          </CategoryProvider>
        </PopupProvider>
       
      </div>

  );
};

export default App;
