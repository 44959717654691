import { gsap } from "gsap";

import { useGSAP } from "@gsap/react";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const ParallaxElements = () => {
  useGSAP(() => {
    const timelineCircTL = gsap.timeline({
      scrollTrigger: {
        trigger: "#cont",
        scroller: "#scrollDemo",
        start: "top center",
        end: "top top",

        scrub: 7,
      },
    });
    timelineCircTL.fromTo(
      "#circleTL",
      { x: 80, y: 190, scale: 0.7 },
      { x: 80, y: 40, scale: 1 }
    );

    const timelineCircleML = gsap.timeline({
      scrollTrigger: {
        trigger: "#cont",
        scroller: "#scrollDemo",
        start: "top center",
        end: "center 60%",
        scrub: 4,
      },
    });

    timelineCircleML.fromTo(
      "#circleML",
      { x: 50, y: 550, scale: 1.5 },
      { x: 70, y: 450, scale: 1 }
    );

    const timelineCircleMR = gsap.timeline({
      scrollTrigger: {
        trigger: "#cont",
        scroller: "#scrollDemo",
        start: "top center",
        end: "center center",
        scrub: 8,
      },
    });

    timelineCircleMR.fromTo(
      "#circleMR",
      { x: 405, y: 0, scale: 0.5 },
      { x: 205, y: 190, scale: 1 }
    );

    const timelineCircleBL = gsap.timeline({
      scrollTrigger: {
        trigger: "#cont",
        scroller: "#scrollDemo",
        start: "center center",
        end: "center 70%",
        scrub: 6,
      },
    });

    timelineCircleBL.fromTo(
      "#circleBL",
      { x: 135, y: 685, scale: 1.25 },
      { x: 25, y: 755, scale: 1 }
    );

    const timelineCircleBR = gsap.timeline({
      scrollTrigger: {
        trigger: "#cont",
        scroller: "#scrollDemo",
        start: "center center",
        end: "center 70%",
        scrub: 4,
      },
    });

    timelineCircleBR.fromTo(
      "#circleBR",
      { x: 270, y: 670, scale: 1.25 },
      { x: 270, y: 770, scale: 1 }
    );
  }, []);

  return (
    <div className="w-[450px] h-[1400px]">
      <img
        src="/assets/Mobile/Home/ServicesSection/Parallax/circleTL.svg"
        alt="Top left pink circle"
        id="circleTL"
        className="absolute"
      />
      <img
        src="/assets/Mobile/Home/ServicesSection/Parallax/circleML.svg"
        alt="Middle left pink circle"
        id="circleML"
        className="absolute"
      />
      <img
        src="/assets/Mobile/Home/ServicesSection/Parallax/circleMR.svg"
        alt="Middle right pink circle"
        id="circleMR"
        className="absolute"
      />

      <img
        src="/assets/Mobile/Home/ServicesSection/Parallax/circleBL.svg"
        alt="Bottom left pink circle"
        id="circleBL"
        className="absolute"
      />
      <img
        src="/assets/Mobile/Home/ServicesSection/Parallax/circleBR.svg"
        alt="Bottom right pink circle"
        id="circleBR"
        className="absolute z-10"
      />
    </div>
  );
};

export default ParallaxElements;
