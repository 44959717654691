import React, { useState } from "react";
import DesignElements from "./DesignElements";
import { toast } from "react-toastify";
import { toastDefaultOptions } from "lib/consts";
import { blogService } from "services/blog";
import { validateEmail } from "lib/utils";

const NewsletterApplicaiton = () => {
  const [email, setEmail] = useState<string>("");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handleSubmit = () => {
    if (email === "") {
      toast.error("Please enter an e-mail.", toastDefaultOptions);
    } else if (!validateEmail(email)) {
      toast.error("Please enter a valid mail address.", toastDefaultOptions);
      return;
    } else {
      blogService
        .subscribeToNewsletter(email, 4)
        .then((response) => {
          if (response?.success) {
            toast.success(
              "You have successfuly subscribed to our newsletter.",
              toastDefaultOptions
            );
          } else toast.error(response?.error?.response?.message, toastDefaultOptions);
        })
        .catch((Error) => {
			
          toast.error(Error, toastDefaultOptions);
        });
    }
  };
  return (
    <div className="absolute z-[50] -translate-x-1/2 left-1/2 w-[1512px] h-[1050px] bottom-0 flex items-start justify-center overflow-y-clip">
      <DesignElements />
      <div className=" z-[50] absolute top-[180px] w-fit min-w-[520px] flex flex-col items-start justify-center">
        <p className="font-poppinsSb text-[41px] leading-[129%] text-squeeze-darkBlue mb-[4px]">
          Get the{" "}
          <span className="font-poppinsB text-squeeze-pink">freshest</span>{" "}
          content
        </p>
        <div className="border-b-[0.73px] border-squeeze-darkBlue w-[512px]"></div>
        <div className="text-squeeze-darkBlue font-robotol text-[20px] leding-[129px] w-full my-[29px]">
          <p>Subscribe to our blog and get notified about every </p>
          <p>blog post we ever write ‘till the end of time.</p>
        </div>
        <div className="flex items-center justify-between w-full leading-[117.5px]">
          <input
            type="email"
            value={email}
            placeholder="Email address"
            className="font-poppinsL text-[17px] focus:outline-none placeholder:text-squeeze-darkBlue text-squeeze-darkBlue border-[0.75px] border-squeeze-darkBlue rounded-[92px] w-[362px] h-[52px] bg-transparent px-[20px]"
            onChange={handleChange}
          />
          <button
            onClick={handleSubmit}
            className="w-[133px] h-[48px] z-40 hover:bg-squeeze-darkBlue cursor-pointer bg-squeeze-pink text-white rounded-[92px] flex items-center justify-center font-poppinsSb text-[16px]"
          >
            SUBSCRIBE
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewsletterApplicaiton;
