import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import ScrollTrigger from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const MobileParallaxElements = () => {
	useGSAP(() => {
		const timelineStars = gsap.timeline({
			scrollTrigger: {
				trigger: "#yellowStars",
				scroller: "#scrollDemo",
				start: "top center",
				scrub: 5,
			},
		});
		timelineStars.fromTo("#yellowStars", { x: 160, y: 330 }, { x: 110, y: 1000, rotate:-70 });

		const timelineBirds = gsap.timeline({
			scrollTrigger: {
				trigger: "#yellowBirds",
				scroller: "#scrollDemo",
				start: "top top",
				scrub: 6,
			},
		});
		timelineBirds.fromTo("#yellowBirds", { x: -90, y: 50 }, { x: -90, y: 780 });
	}, []);
	return (
		<>
			<img
				src='/assets/Mobile/Home/PinkSections/yellow-birds.svg'
				id='yellowBirds'
				alt='yellow birds'
				className='z-30 abasolute '
			/>
			<img
				src='/assets/Mobile/Home/PinkSections/yellow-stars.svg'
				id='yellowStars'
				alt='Yellow stars'
				className='absolute '
			/>
		</>
	);
};

export default MobileParallaxElements;
