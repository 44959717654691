import HeadingText from "./HeadingText";
import DescText from "./DescText";

const ClosingSection = ({ isVisible }: { isVisible: boolean }) => {
	return (
		<div className='h-[520px] w-[450px] flex flex-col items-center justify-start space-y-[58px]'>
			<HeadingText isVisible={isVisible} />
			<DescText isVisible={isVisible} />
		</div>
	);
};

export default ClosingSection;
