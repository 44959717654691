import HeadingTextAnimation from "components/HeadingTextAnimation";

const TextElements = ({ isVisible }: { isVisible: boolean }) => {
  return (
    <>
      <div className="w-fit text-squeeze-darkBlue font-poppinsB text-[40px] leading-[100%]">
        <HeadingTextAnimation
          idx={"1"}
          isVisible={isVisible}
          text="How are we"
          textClass="min-h-[46px] absolute top-[120px] left-[105px]"
        />

        <HeadingTextAnimation
          idx={"2"}
          isVisible={isVisible}
          text="going to get"
          textClass="min-h-[46px] absolute top-[160px] left-[100px]"
        />

        <HeadingTextAnimation
          idx={"3"}
          isVisible={isVisible}
          text="you"
          textClass="min-h-[46px] absolute top-[202px] left-[102px]"
        />
      </div>
      <div className="absolute top-[200px] left-[190px] font-poppinsBli text-[47.74px] leading-[106%] text-squeeze-pink lowercase -rotate-[4deg]">
        <HeadingTextAnimation
          idx={"3"}
          isVisible={isVisible}
          text="There"
          extraClass="min-w-[110%]"
        />
      </div>
      <div className="absolute top-[200px] left-[325px] font-poppinsB text-[47.74px] leading-[106%] text-squeeze-darkBlue lowercase -rotate-[4deg]">
        <HeadingTextAnimation
          idx={"3"}
          isVisible={isVisible}
          text="?"
          extraClass="min-w-[110%]"
        />
      </div>
    </>
  );
};

export default TextElements;
