import Arrows from "components/Home/MobileComponents/GetThereSection/Arrows";
import TextElements from "components/Home/MobileComponents/GetThereSection/TextElements";
import MobileAccordionItems from "./MobileAccordionItems";

const MobileGetThere = ({ isVisible }: { isVisible: boolean }) => {
  return (
    <div className="h-[670px] relative flex flex-col items-center justify-center widthClass overflow-x-visible">
      <div className="w-[450px] h-[670px] flex flex-col items-center justify-center relative">
        <TextElements isVisible={isVisible} />
        <Arrows />
        <img
          src="/assets/Mobile/Home/GetThereSection/uLine.svg"
          alt="underline"
          className={`absolute top-[244px] left-[195px]`}
        />
        {isVisible && <MobileAccordionItems />}
      </div>
    </div>
  );
};

export default MobileGetThere;
