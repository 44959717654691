import { copyToClipboard, toTop } from "lib/utils";
import { Link } from "react-router-dom";

const ContactInfo = () => {
  return (
    <div className="w-full h-[170px] space-y-[50px]  font-robotol text-[11px] mobile-l:text-[13px] text-white ">
      <div className="flex gap-[20px] lowercase">
        <div className="space-y-[8px] mobile-l:space-y-[0px] flex flex-col">
          <Link
            onClick={() => {
              toTop();
            }}
            to="/"
          >
            home
          </Link>
          <Link
            onClick={() => {
              toTop();
            }}
            to="/services"
          >
            services
          </Link>
          <Link
            onClick={() => {
              toTop();
            }}
            to="/blog"
          >
            blog
          </Link>
        </div>
        <div className="space-y-[8px] mobile-l:space-y-[0px] flex flex-col">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/squeezecreativeagency/?hl=en"
          >
            Instagram
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/company/squeeze-creative-agency/?trk=public_post_feed-actor-name"
          >
            LinkedIn
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/profile.php?id=100095734291715&mibextid=ZbWKwL"
          >
            Facebook
          </a>
        </div>
      </div>
      <div className="flex flex-col space-y-[12px] mobile-l:space-y-[10px]">
        <div className="flex flex-col items-start">
          <button
            onClick={() => {
              copyToClipboard("contact@squeezecreative.co");
            }}
            className="cursor-pointer"
          >
            contact@squeezecreative.co
          </button>
          <button
            onClick={() => {
              copyToClipboard("+389 71 350 533");
            }}
            className="cursor-pointer"
          >
            +389 71 350 533
          </button>
        </div>
        <div className="border-b-[0.75px] border-white w-[150px]"></div>
        <div>
          <p>Like our website?</p>
          <a href="https://amalasolutions.com">
            {" "}
            <span className="font-robotom">Amala Solutions</span> made it!
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
