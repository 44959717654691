import { toastDefaultOptions } from "lib/consts";
import { copyToClipboard } from "lib/utils";
import { toast } from "react-toastify";

const ContactSection = () => {
  return (
    <div
      className={`w-full laptop-xl:space-y-[10px] laptop:space-[10px] font-roboto text-center absolute laptop-xl:bottom-[5vh] bottom-[4vh] whitespace-nowrap `}
    >
      <div
        className={`laptop-l:text-[17px] laptop:text-[12px] 4k:text-[32px] text-white`}
      >
        <p
		className="cursor-pointer"
          onClick={() => {
            copyToClipboard("contact@squeezecreative.co");
            toast.success("E-mail copied to clipboard.", toastDefaultOptions);
          }}
        >
          contact@squeezecreative.co
        </p>
        <p
		className="cursor-pointer"
          onClick={() => {
            copyToClipboard("+389 71 350 533");
            toast.success(
              "Phone number copied to clipboard.",
              toastDefaultOptions
            );
          }}
        >
          +389 71 350 533
        </p>
      </div>
      <div className="lowercase gap-[20px] flex justify-center items-center text-squeeze-yellow leading-[74%] laptop-l:text-[15px] laptop:text-[12px] 4k:text-[30px]">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/squeezecreativeagency/?hl=en"
          className=""
        >
          Instagram
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/company/squeeze-creative-agency/?trk=public_post_feed-actor-name"
        >
          LinkedIn
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/profile.php?id=100095734291715&mibextid=ZbWKwL"
        >
          Facebook
        </a>
      </div>
    </div>
  );
};

export default ContactSection;
