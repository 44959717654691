import Eyes from "./Eyes";
import Text from "./Text";

const MobileEyeSection = ({ isVisible }: { isVisible: boolean }) => {
  return (
    <div className="h-[650px] relative flex flex-col items-center justify-center w-[450px] overflow-x-clip">
      <div className="w-[450px] h-[650px]">
        <div className="relative text-white  w-[450px] flex flex-col items-center justify-center">
          <Text isVisible={isVisible} />
          <Eyes />
        </div>
      </div>
    </div>
  );
};
export default MobileEyeSection;
