import ParallaxElements from "./ParallaxElements";
import ServiceLinks from "./ServiceLinks";

const MobileServicesSection = () => {
  return (
    <div className="h-[1090px] relative flex flex-col items-center justify-center widthClass overflow-x-visible">
      <div className="w-[450px] h-[1090px] flex flex-col items-center justify-center">
        <div
          className="w-[450px] h-[1090px] realtive flex flex-col items-center justify-center"
          id="cont"
        >
          <ParallaxElements />
          <ServiceLinks />
        </div>
      </div>
    </div>
  );
};

export default MobileServicesSection;
