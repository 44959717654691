import HeadingTextAnimation from "components/HeadingTextAnimation";
import MobileCards from "./MobileCards";

const MobilePinkSections = ({ trustFlag }: { trustFlag: boolean }) => {
  return (
    <div className="h-[1120px] relative overflow-x-clip flex flex-col items-center justify-center">
      <MobileCards />
      <div
        className="w-[500px] relative h-[805px] flex flex-col items-center justify-center text-white whitespace-nowrap"
        id="trustSection"
      >
        <div className="relative">
          <HeadingTextAnimation
            idx={"1"}
            isVisible={trustFlag}
            text="Trust us"
            extraClass="text-[47px] font-poppinsBl uppercase"
          />
        </div>
        <HeadingTextAnimation
          idx={"3"}
          isVisible={trustFlag}
          text="We understand your problem"
          extraClass="text-[17px] font-poppins text-white"
        />
      </div>
    </div>
  );
};

export default MobilePinkSections;
