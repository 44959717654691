import { gsap } from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useGSAP } from "@gsap/react";
gsap.registerPlugin(ScrollTrigger);

const MobileCards = () => {
  useGSAP(() => {
    const timelinePs = gsap.timeline({
      scrollTrigger: {
        trigger: "#pinkCont",
        scroller: "#scrollDemo",
        start: "top center",
        end: "center center",

        scrub: 5,
      },
    });
    timelinePs.fromTo(
      "#strugM",

      { position: "absolute", x: 37, y: 20, rotate: -10 },
      { position: "absolute", x: 37, y: 0, rotate: -7.31 }
    );

    const timelinePs2 = gsap.timeline({
      scrollTrigger: {
        trigger: "#pinkCont",
        scroller: "#scrollDemo",
        start: "top center",
        end: "center center",

        scrub: 6,
      },
    });
    timelinePs2.fromTo(
      "#engM",

      { position: "absolute", x: 0, y: 190, scale: 1.1 },
      { position: "absolute", x: 0, y: 170, scale: 1, rotate: 7.35 }
    );
    const timelinePs3 = gsap.timeline({
      scrollTrigger: {
        trigger: "#pinkCont",
        scroller: "#scrollDemo",
        start: "top center",
        end: "center center",

        scrub: 6,
      },
    });
    timelinePs3.fromTo(
      "#wresM",

      { position: "absolute", x: 40, y: 330, scale: 0.75 },
      { position: "absolute", x: 40, y: 310, scale: 1, rotate: -2.29 }
    );
  }, []);

  return (
    <div className="h-[805px] w-[210px] relative text-squeeze-darkBlue" id="pinkCont">
      <img
        src="/assets/Mobile/Home/PinkSections/1.svg"
        alt="black rectangle"
        id="strugM"
        className=""
      />

      <img
        src="/assets/Mobile/Home/PinkSections/2.svg"
        alt="white rectangle"
        id="engM"
        className=""
      />

      <img
        src="/assets/Mobile/Home/PinkSections/3.svg"
        alt="black rectangle"
        id="wresM"
        className=""
      />
    </div>
  );
};

export default MobileCards;
