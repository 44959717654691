import SingleEye from "./SingleEye";

const Eyes = () => {
  return (
    <>
      <div className="w-fit h-fit absolute top-[15px] left-[105px]">
        <SingleEye
          folder="Top"
          id="1"
          eyeClass="top-[5px] left-[70px]"
          glareClass="absolute top-[20px] left-[70px]"
        />
      </div>
      <div className="w-fit h-fit absolute top-[145px] left-[265px]">
        <SingleEye
          folder="TopRight"
          id="2"
          eyeClass="top-[4px] left-[30px]"
          glareClass="absolute top-[9px] left-[30px]"
        />
      </div>
      <div className="w-fit h-fit absolute top-[170px] left-[75px]">
        <SingleEye
          folder="TopLeft"
          id="3"
          eyeClass="top-[8px] left-[28px]"
          glareClass="absolute top-[15px] left-[33px]"
        />
      </div>
      <div className="w-fit h-fit absolute top-[452px] left-[230px]">
        <SingleEye
          folder="BottomRight"
          id="5"
          eyeClass="top-[7px] left-[50px]"
          glareClass="absolute top-[17px] left-[48px]"
        />
      </div>
      <div className="w-fit h-fit absolute top-[550px] left-[80px]">
        <SingleEye
          folder="BottomLeft"
          id="4"
          eyeClass="top-[7px] left-[40px]"
          glareClass="absolute top-[15px] left-[40px]"
        />
      </div>
    </>
  );
};

export default Eyes;
