import { copyToClipboard } from "lib/utils";
import { isSafari } from "react-device-detect";

const Contact = () => {
  return (
    <div
      className={`absolute tablet:justify-between tablet:items-center tablet:flex ${
        isSafari ? "bottom-[10vh]" : "bottom-[5vh]"
      } text-[14px] mobile-l:text-[18px] font-roboto pb-[8vh] w-full px-[5vw] tablet:space-y-0 space-y-[13px] mobile-l:space-y-[16px]`}
    >
      <div className=" text-white tablet:gap-3 leading-[99%] max-tablet:space-y-[13px] flex max-tablet:flex-col tablet:items-center ">
        <div onClick={() => copyToClipboard("contact@squeezecreative.co")}>
          contact@squeezecreative.co
        </div>
        <div onClick={() => copyToClipboard("+389 71 350 533")}>
          +389 71 350 533
        </div>
      </div>
      <div className="border-b-[0.5px] border-b-white"></div>
      <div className="flex gap-[10px] text-squeeze-yellow leading-[42%]">
        <a href="https://www.instagram.com/squeezecreativeagency/?hl=en">
          instagram
        </a>
        <a href="https://www.linkedin.com/company/squeeze-creative-agency/?trk=public_post_feed-actor-name">
          linkedin
        </a>
        <a href="https://www.facebook.com/profile.php?id=100095734291715&mibextid=ZbWKwL">
          facebook
        </a>
      </div>
    </div>
  );
};

export default Contact;
