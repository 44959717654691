import HeadingTextAnimation from "components/HeadingTextAnimation";

const Text = ({ isVisible }: { isVisible: boolean }) => {
  return (
    <div className="-space-y-[10px] absolute top-[260px]  transform  flex flex-col items-center justify-center">
      <div>
        <HeadingTextAnimation
          text="Look at us"
          extraClass=" font-poppinsEb text-[37px]"
          idx="1"
          isVisible={isVisible}
        />
      </div>

      <div className="flex w-fit h-fit text-[33px] gap-[12px] text-white font-poppinsL">
        <div>
          <HeadingTextAnimation
            text={"being all "}
            isVisible={isVisible}
            idx={"2"}
            extraClass=""
          />
        </div>
        <div>
          <HeadingTextAnimation
            text={"bark"}
            isVisible={isVisible}
            idx={"4"}
            extraClass="text-squeeze-yellow font-poppinsB"
          />
        </div>
        <div>
          <HeadingTextAnimation
            text={","}
            isVisible={isVisible}
            idx={"2"}
            extraClass="font-poppinsB -ml-[12px]"
          />
        </div>
      </div>
      <div className="flex w-fit h-fit text-[33px] text-white font-poppinsL gap-[12px]">
        <div>
          <HeadingTextAnimation
            text={"no"}
            isVisible={isVisible}
            idx={"3"}
            extraClass=""
          />
        </div>
        <div>
          <HeadingTextAnimation
            text={"bite"}
            isVisible={isVisible}
            idx={"4"}
            extraClass="text-squeeze-yellow font-poppinsB"
          />
        </div>
        <div>
          <HeadingTextAnimation
            text={"huh?"}
            isVisible={isVisible}
            idx={"3"}
            extraClass=" "
          />
        </div>
      </div>
    </div>
  );
};

export default Text;
