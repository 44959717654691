import React, { useEffect, useState } from "react";
import Contact from "./Contact";
import MobileLinks from "./MobileLinks";
import { isSafari } from "react-device-detect";

const OpenMobileHeader = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [animateOpen, setAnimateOpen] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isOpen) {
      timeout = setTimeout(() => {
        setAnimateOpen(true);
      }, 1);
    } else {
      setAnimateOpen(false);
    }

    return () => clearTimeout(timeout);
  }, [isOpen]);

  return (
    <div className={`fixed top-0 right-0 z-[300] h-screen `}>
      <div
        className={`${isSafari?'pb-[10vh]':'pb-[5vh]'} flex flex-col items-center justify-center px-[5vw] bg-squeeze-pink transition-[width] ease-in-out duration-600 z-[300] overflow-x-clip ${
          animateOpen ? "w-screen" : "w-0"
        } h-full `}
      >
        <MobileLinks setIsOpen={setIsOpen} />
        <Contact />
      </div>
    </div>
  );
};

export default OpenMobileHeader;
